import React from "react";
import classes from './Guarantees.module.scss';
import { useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/HomePage/Guarantees/icon_01.svg';
import icon_02 from '../../../assets/img/HomePage/Guarantees/icon_02.svg';
import icon_03 from '../../../assets/img/HomePage/Guarantees/icon_03.svg';
import icon_04 from '../../../assets/img/HomePage/Guarantees/icon_04.svg';
import Button from "../../GlobalComponents/Button/Button";

const cards = [
    {
        icon: icon_01,
        subtitle: 'home.guarantees.card_01.subtitle',
        desc: 'home.guarantees.card_01.desc',
    },
    {
        icon: icon_02,
        subtitle: 'home.guarantees.card_02.subtitle',
        desc: 'home.guarantees.card_02.desc',
    },
    {
        icon: icon_03,
        subtitle: 'home.guarantees.card_03.subtitle',
        desc: 'home.guarantees.card_03.desc',
    },
    {
        icon: icon_04,
        subtitle: 'home.guarantees.card_04.subtitle',
        desc: 'home.guarantees.card_04.desc',
    },
];

const Guarantees = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <div className={classes.head}>
                    <h2 className={`${classes.title} font-40`}>
                        {t('home.guarantees.title')}
                    </h2>
                    <p className={`${classes.text} font-28`}>
                        {t('home.guarantees.text')}
                    </p>
                    <div className={classes.btn}>
                        <Button mt />
                    </div>
                </div>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={classes.card}>
                            <img className={classes.icon} src={card.icon} alt='' />
                            <div>
                                <h4 className={`${classes.subtitle} font-18`}>
                                    {t(card.subtitle)}
                                </h4>
                                <p className={`${classes.desc} font-16`}>
                                    {t(card.desc)}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Guarantees;
