import React from "react";
import classes from "./Footer.module.scss";
import { useTranslation } from "react-i18next";
import logo from '../../../assets/img/logo_new.svg';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className={`${classes.wrap} mt`}>
      <div className="container font-16">
        <div className={classes.footer}>
          <img className={classes.logo} src={logo} alt='' />
          <a href="/docs/TERMS_AND_CONDITIONS.pdf" target="_blank">
            {t('footer.docs.terms_and_conditions')}
          </a>
          <p>
            Rudiq 2025. {t('footer.rights')}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
