import React from "react";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/FaqPage/Banner/Banner";
import Faqs from "../components/FaqPage/Faqs/Faqs";

const FaqPage = () => {
  return (
    <div className="ovf-hidden">
      <Header />
      <Banner />
      <Faqs />
      <Footer />
    </div>
  );
};

export default FaqPage;
