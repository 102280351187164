import React from "react";
import classes from './Banner.module.scss';

const Banner = () => {
    return (
        <div className={classes.wrap}></div>
    );
};

export default Banner;
