import React from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/HomePage/banner.avif';
import logo from '../../../assets/img/logo.svg';
import Button from "../../GlobalComponents/Button/Button";

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.content}>
                    <div className={classes.head}>
                        <img className={classes.logo} src={logo} alt='Rudiq' />
                        <h2 className={`${classes.subtitle} font-28`}>
                            {t('home.banner.subtitle')}
                        </h2>
                    </div>
                    <div className={`${classes.text} font-16 txt-container`}>
                        <p>
                            {t('home.banner.text_01')}
                        </p>
                        <p>
                            {t('home.banner.text_02')}
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <Button mt />
                    </div>
                </div>
                <img className={classes.image} src={image} alt='' />
                <div className={`${classes.mobText} font-16 txt-container`}>
                    <p>
                        {t('home.banner.text_01')}
                    </p>
                    <p>
                        {t('home.banner.text_02')}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default Banner;
