import React from "react";
import classes from './WhyChoose.module.scss';
import { useTranslation } from "react-i18next";
import image_01 from '../../../assets/img/HomePage/WhyChoose/image_01.webp';
import image_02 from '../../../assets/img/HomePage/WhyChoose/image_02.webp';
import image_03 from '../../../assets/img/HomePage/WhyChoose/image_03.webp';

const cards = [
    {
        class: classes.card_01,
        image: image_01,
        subtitle: 'home.why_choose.card_01.subtitle',
        desc: 'home.why_choose.card_01.desc'
    },
    {
        class: classes.card_02,
        image: image_02,
        subtitle: 'home.why_choose.card_02.subtitle',
        desc: 'home.why_choose.card_02.desc'
    },
    {
        class: classes.card_03,
        image: image_03,
        subtitle: 'home.why_choose.card_03.subtitle',
        desc: 'home.why_choose.card_03.desc'
    },
    {
        class: classes.card_04,
        subtitle: 'home.why_choose.card_04.subtitle',
        desc: 'home.why_choose.card_04.desc'
    },
];

const WhyChoose = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.wrap}>
                <h2 className={`${classes.title} font-40`}>
                    {t('home.why_choose.title')}
                </h2>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={`${classes.card} ${card.class}`}>
                            <div className={classes.content}>
                                <h4 className={`${classes.subtitle} font-18`}>
                                    {t(card.subtitle)}
                                </h4>
                                <p className={`${classes.desc} font-16`}>
                                    {t(card.desc)}
                                </p>
                            </div>
                            {card.image &&
                                <img className={classes.image} src={card.image} alt='' />
                            }
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WhyChoose;
