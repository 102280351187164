import React, { useState } from "react";
import classes from './Tabs.module.scss';
import { useTranslation } from "react-i18next";
import logo from '../../../assets/img/logo.svg';
import image_01 from '../../../assets/img/ServicesPage/Tabs/image_01.avif';
import image_02 from '../../../assets/img/ServicesPage/Tabs/image_02.avif';
import image_03_01 from '../../../assets/img/ServicesPage/Tabs/image_03_01.avif';
import image_03_02 from '../../../assets/img/ServicesPage/Tabs/image_03_02.avif';
import image_03_03 from '../../../assets/img/ServicesPage/Tabs/image_03_03.avif';
import image_04_01 from '../../../assets/img/ServicesPage/Tabs/image_04_01.avif';
import image_04_02 from '../../../assets/img/ServicesPage/Tabs/image_04_02.avif';
import image_04_03 from '../../../assets/img/ServicesPage/Tabs/image_04_03.avif';
import Button from "../../GlobalComponents/Button/Button";

const buttons = [
    'services.tabs.tab_01.title',
    'services.tabs.tab_02.title',
    'services.tabs.tab_03.title',
    'services.tabs.tab_04.title',
];

const Tabs = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);
    return (
        <section className="container">
            <div className={classes.wrap}>
                <div className={classes.head}>
                    <div className={classes.buttonsWrap}>
                        <h1 className={`${classes.subtitle} font-23`}>
                            {t('services.tabs.title')}
                        </h1>
                        <div className={`${classes.buttons} font-18 no-select`}>
                            {buttons.map((btn, index) =>
                                <div
                                    key={index}
                                    onClick={() => setActive(index)}
                                    className={`${classes.button} ${index === active ? classes.active : ''}`}
                                >
                                    {t(btn)}
                                    <svg className={classes.arrow} xmlns="http://www.w3.org/2000/svg" width="11" height="21" viewBox="0 0 11 21" fill="none">
                                        <path d="M1 19.5L10 10.5L1 1.5" stroke="#434343" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classes.banner}>
                        <img className={classes.logo} src={logo} alt='' />
                        <div className={`${classes.text} font-16 txt-container`}>
                            <p>
                                {t('services.banner.text_01')}
                            </p>
                            <p>
                                {t('services.banner.text_02')}
                            </p>
                        </div>
                        <Button mt dark />
                    </div>
                </div>
                <div className={classes.content}>
                    <div className={`${classes.tab} ${active === 0 ? classes.active : ''}`}>
                        <h2 className={`${classes.title} font-28-mark`}>
                            {t('services.tabs.tab_01.title')}
                        </h2>
                        <img className={classes.image} src={image_01} alt='' />
                        <div className={`${classes.text} font-16 txt-container`}>
                            <p>
                                {t('services.tabs.tab_01.text_01')}
                            </p>
                            <p>
                                {t('services.tabs.tab_01.text_02')}
                            </p>
                            <p>
                                {t('services.tabs.tab_01.text_03')}
                            </p>
                        </div>
                        <p className={`${classes.desc} font-16`}>
                            {t('services.tabs.tab_01.desc')}
                        </p>
                        <div className={classes.mainBlock}>
                            <h3 className={`${classes.title} font-28-mark`}>
                                {t('services.tabs.tab_01.subtitle')}
                            </h3>
                            <p className="font-16">
                                {t('services.tabs.tab_01.text_04')}
                            </p>
                        </div>
                    </div>
                    <div className={`${classes.tab} ${active === 1 ? classes.active : ''}`}>
                        <h2 className={`${classes.title} font-28-mark`}>
                            {t('services.tabs.tab_02.title')}
                        </h2>
                        <img className={classes.image} src={image_02} alt='' />
                        <div className={`${classes.text} font-16 txt-container`}>
                            <p>
                                {t('services.tabs.tab_02.text_01')}
                            </p>
                            <p>
                                {t('services.tabs.tab_02.text_02')}
                            </p>
                            <p>
                                {t('services.tabs.tab_02.text_03')}
                            </p>
                        </div>
                    </div>
                    <div className={`${classes.tab} ${active === 2 ? classes.active : ''}`}>
                        <h2 className={`${classes.title} font-28-mark`}>
                            {t('services.tabs.tab_03.title')}
                        </h2>
                        <div className={classes.blocks}>
                            <div className={classes.block}>
                                <img className={classes.image} src={image_03_01} alt='' />
                                <h4 className={`${classes.subtitle} font-16-mark`}>
                                    {t('services.tabs.tab_03.block_01.subtitle')}
                                </h4>
                                <div className={`${classes.text} font-16 txt-container`}>
                                    <p>
                                        {t('services.tabs.tab_03.block_01.text_01')}
                                    </p>
                                    <p>
                                        {t('services.tabs.tab_03.block_01.text_02')}
                                    </p>
                                </div>
                            </div>
                            <div className={classes.block}>
                                <img className={classes.image} src={image_03_02} alt='' />
                                <h4 className={`${classes.subtitle} font-16-mark`}>
                                    {t('services.tabs.tab_03.block_02.subtitle')}
                                </h4>
                                <div className={`${classes.text} font-16 txt-container`}>
                                    <p>
                                        {t('services.tabs.tab_03.block_02.text_01')}
                                    </p>
                                    <p>
                                        {t('services.tabs.tab_03.block_02.text_02')}
                                    </p>
                                </div>
                            </div>
                            <div className={classes.block}>
                                <img className={classes.image} src={image_03_03} alt='' />
                                <h4 className={`${classes.subtitle} font-16-mark`}>
                                    {t('services.tabs.tab_03.block_03.subtitle')}
                                </h4>
                                <div className={`${classes.text} font-16 txt-container`}>
                                    <p>
                                        {t('services.tabs.tab_03.block_03.text_01')}
                                    </p>
                                    <p>
                                        {t('services.tabs.tab_03.block_03.text_02')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${classes.tab} ${active === 3 ? classes.active : ''}`}>
                        <h2 className={`${classes.title} font-28-mark`}>
                            {t('services.tabs.tab_04.title')}
                        </h2>
                        <div className={classes.blocks}>
                            <div className={classes.block}>
                                <img className={classes.image} src={image_04_01} alt='' />
                                <h4 className={`${classes.subtitle} font-16-mark`}>
                                    {t('services.tabs.tab_04.block_01.subtitle')}
                                </h4>
                                <div className={`${classes.text} font-16 txt-container`}>
                                    <p>
                                        {t('services.tabs.tab_04.block_01.text_01')}
                                    </p>
                                </div>
                            </div>
                            <div className={classes.block}>
                                <img className={classes.image} src={image_04_02} alt='' />
                                <h4 className={`${classes.subtitle} font-16-mark`}>
                                    {t('services.tabs.tab_04.block_02.subtitle')}
                                </h4>
                                <div className={`${classes.text} font-16 txt-container`}>
                                    <p>
                                        {t('services.tabs.tab_04.block_02.text_01')}
                                    </p>
                                </div>
                            </div>
                            <div className={classes.block}>
                                <img className={classes.image} src={image_04_03} alt='' />
                                <h4 className={`${classes.subtitle} font-16-mark`}>
                                    {t('services.tabs.tab_04.block_03.subtitle')}
                                </h4>
                                <div className={`${classes.text} font-16 txt-container`}>
                                    <p>
                                        {t('services.tabs.tab_04.block_03.text_01')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Tabs;
