import _ from 'lodash';
import $ from "jquery";
import i18n from "i18next";
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { headerRoutes } from "../../../router";
import logo from "../../../assets/img/logo_new.svg";
import Button from '../Button/Button';
import Marquee from "react-fast-marquee";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import classes from "./Header.module.scss";

const languages = [
  { value: "en", label: "EN" },
];

const Header = () => {
  const { t } = useTranslation();
  const [burgerOpened, setBurgerOpened] = useState(false);
  const { pathname } = useLocation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const headerRef = useRef(null);

  const getRoute = (route) => {
    if (lang && route) {
      return `/${lang}${route}`;
    }
    return `/${i18n.language}${route}`;
  }

  const setLocale = (newLang) => {
    let newPathname = pathname;
    i18n.changeLanguage(newLang);

    if (lang && newLang) {
      newPathname = pathname.replace(`/${lang}`, `/${newLang}`);
    }

    navigate(newPathname);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const langs = _.dropRight(i18n.options.supportedLngs);
    if (lang === undefined || !langs.includes(lang)) {
      setLocale('en');
    }
    // eslint-disable-next-line
  }, [pathname]);

  const listenScroll = () => {
    if (window.pageYOffset > 70) {
      $(headerRef.current).removeClass(classes.hideBg);
    } else {
      $(headerRef.current).addClass(classes.hideBg);
    }
  }

  useEffect(() => {
    listenScroll();
    burgerOpened
      ? $("body").addClass("lock")
      : $("body").removeClass("lock");
  }, [burgerOpened]);

  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      listenScroll();
    });
    listenScroll();
    setBurgerOpened(false);
  }, [pathname]);

  useEffect(() => {
    $("body").hasClass("lock")
      && $("body").removeClass("lock");
  }, []);

  window.addEventListener("resize", (event) => {
    setBurgerOpened(false);
  });

  const scrollToForm = () => {
    navigate(`/${lang}/`);

    setTimeout(() => {
      const element = document.getElementById('feedback-form');

      if (element) {
        const offset = 200;
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  return (
    <div
      ref={headerRef}
      className={`${classes.header} ${burgerOpened && classes.headerActive} ${classes.hideBg} no-select`}
    >
      <div className={["container", classes.container].join(" ")}>
        <div className={classes.headerRow}>
          <div className={classes.headerRowLogo}>
            <Link
              to={getRoute('/')}
              onClick={() => setBurgerOpened(false)}
            >
              <img src={logo} alt="" />
            </Link>
          </div>
          <div className={classes.headerRowLinks}>
            {headerRoutes.map((route, routeIndex) => (
              route.path ?
                <Link
                  key={routeIndex}
                  to={getRoute(route.path)}
                  className={classes.linkItem}
                >
                  {t(route.text)}
                </Link>
                :
                <div key={routeIndex} className={classes.listWrap}>
                  <div className={classes.linkItem}>
                    {t(route.text)}
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="7" viewBox="0 0 13 7" fill="none">
                      <path d="M1 1L6.66667 6L12 1" stroke="black" strokeOpacity="0.7" strokeWidth="1.2" />
                    </svg>
                  </div>
                  <div className={classes.subRoutes}>
                    {route.routes.map((subRoute, subRouteIndex) =>
                      <Link
                        key={subRouteIndex}
                        className={`${classes.subRoute} ${classes.linkItem}`}
                        to={getRoute(subRoute.path)}
                      >
                        {t(subRoute.text)}
                      </Link>
                    )}
                  </div>
                </div>
            ))}
          </div>
          <div className={classes.headerRowRight}>
            <div className={classes.langs}>
              <Dropdown
                options={languages}
                onChange={(e) => setLocale(e.value)}
                value={lang}
                placeholder="EN"
              />
            </div>
            <div
              onClick={scrollToForm}
              className={classes.login}
            >
              {t('btn.start_now')}
            </div>
            <div
              onClick={() => setBurgerOpened((value) => !value)}
              className={`${classes.burger} ${burgerOpened && classes.burgerActive}`}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div className={`${classes.mobileMenu} ${burgerOpened && classes.mobileMenuActive}`}>
        <div className="container">
          {headerRoutes.map((route, routeIndex) => (
            route.path ?
              <Link
                key={routeIndex}
                to={getRoute(route.path)}
                className={`${classes.aLink}`}
                onClick={() => setBurgerOpened(false)}
              >
                {t(route.text)}
              </Link>
              :
              route.routes.map((subRoute, subRouteIndex) =>
                <Link
                  key={subRouteIndex}
                  to={getRoute(subRoute.path)}
                  className={`${classes.aLink}`}
                  onClick={() => setBurgerOpened(false)}
                >
                  {t(subRoute.text)}
                </Link>
              )
          ))}
          <div className={classes.btn}>
            <Button white login>
              {t('btn.start_now')}
            </Button>
          </div>
        </div>
      </div>
      <div className={classes.lineWrap}>
        <div className="container font-20">
          <div className={classes.line}>
            <p className={classes.join}>
              {t('header.text_01')}
            </p>
            <div className={classes.marquee}>
              <Marquee autoFill speed={80}>
                {t('header.text_02')}&nbsp;
              </Marquee>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;