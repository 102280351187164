import React from "react";
import classes from "./Button.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const Button = ({ children, mt, event, dark }) => {
  const { t } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();

  const btnClasses = `${classes.button} ${dark ? classes.dark : ''} ${mt ? 'mt-btn' : ''} no-select`;

  const scrollToForm = () => {
    navigate(`/${lang}/`);

    setTimeout(() => {
      const element = document.getElementById('feedback-form');

      if (element) {
        const offset = window.innerWidth > 600 ? 200 : 10;
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  return (
    <div
      className={btnClasses}
      onClick={event ? event : scrollToForm}
    >
      <span>
        {children
          ? children
          : t('btn.start_now')
        }
      </span>
    </div>
  );
};

export default Button;