import React from "react";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/ServicesPage/Banner/Banner";
import Tabs from "../components/ServicesPage/Tabs/Tabs";

const ServicesPage = () => {
  return (
    <div className="ovf-hidden">
      <Header />
      <Banner />
      <Tabs />
      <Footer />
    </div>
  );
};

export default ServicesPage;
