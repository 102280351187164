import React, { useState } from "react";
import classes from './Faqs.module.scss';
import { useTranslation } from "react-i18next";

const tabs = [
    {
        subtitle: 'faqs.tab_01.subtitle',
        text: 'faqs.tab_01.text',
    },
    {
        subtitle: 'faqs.tab_02.subtitle',
        text: 'faqs.tab_02.text',
    },
    {
        subtitle: 'faqs.tab_03.subtitle',
        text: 'faqs.tab_03.text',
    },
    {
        subtitle: 'faqs.tab_04.subtitle',
        text: 'faqs.tab_04.text',
    },
    {
        subtitle: 'faqs.tab_05.subtitle',
        text: 'faqs.tab_05.text',
    },
    {
        subtitle: 'faqs.tab_06.subtitle',
        text: 'faqs.tab_06.text',
    },
    {
        subtitle: 'faqs.tab_07.subtitle',
        text: 'faqs.tab_07.text',
    },
    {
        subtitle: 'faqs.tab_08.subtitle',
        text: 'faqs.tab_08.text',
    },
    {
        subtitle: 'faqs.tab_09.subtitle',
        text: 'faqs.tab_09.text',
    },
    {
        subtitle: 'faqs.tab_10.subtitle',
        text: 'faqs.tab_10.text',
    },
];

const Faqs = () => {
    const { t } = useTranslation();
    const [active, setActive] = useState(0);

    const setTab = (index) =>
        setActive(active === index ? -1 : index);

    return (
        <section className="container">
            <div className={classes.wrap}>
                <h1 className={`${classes.title} font-23`}>
                    {t('faqs.title')}
                </h1>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <div
                            key={index}
                            onClick={() => setTab(index)}
                            className={`${classes.tab} ${active === index ? classes.active : ''} no-select`}
                        >
                            <div className={classes.head}>
                                <h4 className={`${classes.subtitle} font-18`}>
                                    {t(tab.subtitle)}
                                </h4>
                                <div className={classes.status}></div>
                            </div>
                            <p className={`${classes.text} font-16`}>
                                {t(tab.text)}
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default Faqs;
