import React from "react";
import Header from "../components/GlobalComponents/Header/Header";
import Footer from "../components/GlobalComponents/Footer/Footer";
import Banner from "../components/HomePage/Banner/Banner";
import WhyChoose from "../components/HomePage/WhyChoose/WhyChoose";
import Guarantees from "../components/HomePage/Guarantees/Guarantees";
import Form from "../components/GlobalComponents/Form/Form";

const HomePage = () => {
  return (
    <div className="ovf-hidden">
      <Header />
      <Banner />
      <WhyChoose />
      <Guarantees />
      <Form />
      <Footer />
    </div>
  );
};

export default HomePage;
