import logo from '../assets/img/logo.svg';
import image_01 from '../assets/img/HomePage/banner.avif';
import image_02 from '../assets/img/ServicesPage/banner.avif';
import image_03 from '../assets/img/FaqPage/banner.avif';

export const images = [
    logo,
    image_01,
    image_02,
    image_03,
];
