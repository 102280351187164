import axios from 'axios';
import { useEffect, useState } from "react";
import classes from './Input.module.scss';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Phone = ({
    setValue,
    valid = true,
}) => {
    const [countryCode, setCountryCode] = useState('');

    useEffect(() => {
        const fetchUserCountry = async () => {
            try {
                const response = await axios.get("https://ipinfo.io/json");
                const country = response.data.country.toLowerCase();
                setCountryCode(country);
            } catch (error) {
                console.error("error");
                setCountryCode('gb')
            }
        };

        fetchUserCountry();
    }, []);

    return (
        <div className={classes.inputField}>
            <PhoneInput
                country={countryCode}
                onChange={(phone) => setValue(phone)}
                className={`${classes.phone}`}
                containerClass={classes.containerClass}
                inputClass={`${classes.inputClass} ${!valid ? classes.error : ''}`}
                buttonClass={classes.buttonClass}
                dropdownClass={classes.dropdownClass}
                searchClass={classes.searchClass}
            />
        </div>
    );
};

export default Phone;
